@import '../../../styles/variables';

.notification-wrapper {
    margin-top: calc(3.2rem - 13px);

    .notification-container {
        overflow-x: scroll;
        scrollbar-width: 8px;
        
        .notification-slider {
            display: flex;
            gap: 20px;
            padding-right: 50px;
            padding-top: 10px;
            padding-bottom: 5px;
            width: max-content;
        
            .notification-card {
                max-width: 410px;
                height: min-content;
                max-height: 200px;
                border: 1px double transparent;
                border-radius: 6px;
                background-image: $background-image;
                background-origin: border-box;
                background-clip: padding-box, border-box;
                box-shadow: $box-shadow--panel;
                overflow: hidden;
                flex-grow: 1;
                transition: flex-grow 0.3s ease;
        
                .notification-content {
                    width: 100%;
                    max-height: 200px;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
        
                    .title-row {
                        width: max-content;
                        max-width: 378px;
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        cursor: pointer;
        
                        .title {
                            font-size: 20px;
                            line-height: 24px;
                            font-weight: 500;
                        }
        
                        .material-icon {
                            font-size: 24px;
                            line-height: 24px;
                        }
                    }
                    
                    .description {
                        width: 378px;
                        padding: 5px 5px 5px 32px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19px;
                        overflow-y: scroll;
                        -ms-overflow-style: scrollbar;
                        /* mask-image: linear-gradient(
                            to bottom,
                            transparent 0%,
                            black 5px,
                            black calc(100% - 5px),
                            transparent 100%
                        ); - have text fade out smoothly */
                        transition: opacity 1s ease-in-out;
                        cursor: default;
    
                        a {
                            color: $text;
                        }
                    }
        
                    .close-btn {
                        height: 27px;
                        border: none;
                        border-radius: 2px;
                        margin-left: auto;
                        color: $text;
                        transition: all .5s ease-in-out;
        
                        &:hover {
                            background-color: $off-white;
                        }
                    }
                }
        
                // update card
                &.update-card {
                    color: $background;

                    .notification-content {
                        background: $primary;
                    }
        
                    .close-btn {
                        background: $background;
                    }
                }
        
                // error card
                &.error-card {
                    color: $background;

                    .notification-content {
                        background: $status--failure;
                    }
        
                    .close-btn {
                        background: $background;
                    }
                }
        
                // warning card
                &.warning-card {
                    color: $text;

                    .notification-content {
                        background: $status--to-be-monitored;
                    }
        
                    .close-btn {
                        background: $background;
                    }
                }
        
                // information card
                &.information-card {
                    // background: white;
        
                    .close-btn {
                        background: transparent;
                        border: 1px solid $text !important;
                    }
                }
    
                // minimized state
                &.minimized {
    
                    .description {
                        opacity: 0;
                    }
    
                    .close-btn {
                        opacity: 0;
                    }
                }
            }
        }
    }
    
    // move scroll bar from bottom to top
    .notification-container, .notification-container .notification-slider {
        transform:rotateX(180deg);
        -ms-transform:rotateX(180deg); /* IE 9 */
        -webkit-transform:rotateX(180deg); /* Safari and Chrome */
    }
}
