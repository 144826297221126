// This file is generated by scss_variables.json. Do not edit directly. Head over to the SCSS_VARIABLES.md Readme to learn more.

$bp--md: 992px;
$bp--lg: 1200px;
$bp--xl: 1440px;
$bp--xxl: 1800px;

$animation-duration: .35s;
$max-width: 1920px;
$nav-height: 75px;
$nav-height--condensed: 65px;

$text: #475569;
$text--darker: darken($text, 10%);
$text--dark: darken($text, 20%);
$text--lighter: lighten($text, 10%);
$text--light: lighten($text, 20%);
$text--super-light: lighten($text, 30%);
$text--active: #404040;

$background: #F3F2FB;
$background--light: lighten($background, 10%);
$background--dark: darken($background, 10%);

$grey: #C4C4C4;
$grey--light: #EDEDED;
$grey--strong: #94A3B8;
$grey--table: #E8E8E8;

$primary: #8B71D2;
$primary--lighter: lighten($primary, 10%);
$primary--light: lighten($primary, 20%);
$primary--super-light: lighten($primary, 30%);
$primary--darker: darken($primary, 10%);
$primary--dark: #6A21B8;

$secondary: #B7AFEA;
$secondary--darker: darken($secondary, 8%);
$secondary--dark: darken($secondary, 12%);
$secondary--lighter: lighten($secondary, 8%);
$secondary--light: lighten($secondary, 12%);
$secondary--super-light: #D8D4F3;

$tertiary--lighter: #B7B0E4;
$tertiary: #5400AE;
$tertiary--dark: darken($tertiary, 10%);

$status--ok: #027B75;
$status--to-be-monitored: #F8BB00;
$status--potential-failure: #DD7900;
$status--failure: #B92001;
$status--no-data: #C4C4C4;

$white: #ffffff;
$off-white: #F6F6F7;
$midnight: #190446;
$box-shadow: 0px 1px 4px 0px #0000002E;
$box-shadow--focus: 0px 0px 0px 2px rgba($primary--dark, .35);
$box-shadow--panel: rgba(0, 0, 0, 0.08) 0px 0px 10px;

$border-image: linear-gradient(98deg, rgba(0, 0, 0, 0.2) 7.5%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.1) 54%, rgba(0, 0, 0, 0.2) 88%) 1;
$background-image: linear-gradient(white, white), linear-gradient(140deg, rgba(0, 0, 0, 0.24) 10.5%, rgba(0, 0, 0, 0.12) 40% 54%, rgba(0, 0, 0, 0.24) 88%);