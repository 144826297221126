@import '../../../styles/variables'; 

* {
    // scroll-behavior smooth would break the synced scroll of table
    scroll-behavior: unset;
}

.cdk-drag-placeholder {
    display: inline-flex;
    background: $background;
    border-left: 5px solid $primary--lighter;
    height: 40px;
    padding: 10px 0px 0px 0px;
    margin: 0 5px;
    overflow: hidden;
    transition: all $animation-duration cubic-bezier(0, 0, 0.2, 1);

    .column-search,
    app-dropdown-button {
        display: none;
    }
}

.cdk-drag-preview {
    display: block;
    background: rgba($background, .9);
    padding: 10px 0px 0px 0px;
    border-radius: 2px;
    box-shadow: $box-shadow;
    word-break: keep-all;
    white-space: nowrap;
    box-sizing: content-box;
    font-weight: bold;
    max-height: 40px;

    .column-search,
    app-dropdown-button {
        display: none;
    }
}

.page-num-input {
    width: 60px !important;
}

.overview-container {
    transition: all .5s ease-in-out;

    .table-parent {
        position: relative;
        width: 100%;
    
        .table-cont {
            display: grid;
            position: relative;
            width: fit-content;
            border: 1px double transparent;
            border-radius: 6px;
            background-image: $background-image;
            background-origin: border-box;
            background-clip: padding-box, border-box;
            box-shadow: $box-shadow--panel;
            margin-top: .6rem;
            overflow: hidden;

            .overlay {
                content: '';
                position: absolute;
                pointer-events: none;
                z-index: 5;
                background:
                    linear-gradient(to bottom, rgba(0, 0, 0, 0.03), transparent 8px), /* Top edge */
                    linear-gradient(to top, rgba(0, 0, 0, 0.03), transparent 8px),    /* Bottom edge */
                    linear-gradient(to right, rgba(0, 0, 0, 0.03), transparent 8px), /* Left edge */
                    linear-gradient(to left, rgba(0, 0, 0, 0.03), transparent 8px);  /* Right edge */
                background-size: 100% 8px, 100% 8px, 8px 100%, 8px 100%;
                background-position: top, bottom, left, right;
                background-repeat: no-repeat;
            }

            &.other-state {
                min-height: 300px;
            }

            .scrollbar-wrapper-x {
                position: absolute;
                height: 18px;
                z-index: 4;
                border-left: 1px solid $grey--table;
                border-right: 1px solid $grey--table;

                &.top {
                    top: 0px;
                    grid-column: 1;
                    grid-row: 1;
                    border-bottom: 1px solid $grey--table;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -9999px;
                        bottom: -1px;
                        width: 99999px;
                        height: 1px;
                        background: $grey--table;
                    }
                }
                &.bottom {
                    bottom: 0px;
                    grid-column: 1;
                    grid-row: 3;
                    border-top: 1px solid $grey--table;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -9999px;
                        top: -1px;
                        width: 99999px;
                        height: 1px;
                        background: $grey--table;
                    }
                }

                .sync-scroll-x {
                    height: 8px;
                    width: 100%;
                    overflow-x: scroll;
                    margin-top: 5px;
                    div { height: 1px; }
                }
            }

            .scrollbar-wrapper-y {
                grid-column: 2;
                grid-row: 1 / span 3;
                position: absolute;
                width: 18px;
                z-index: 4;
                border-top: 1px solid $grey--table;
                border-bottom: 1px solid $grey--table;
                border-left: 1px solid $grey--table;

                &::before {
                    content: '';
                    position: absolute;
                    left: -1px;
                    top: -9999px;
                    width: 1px;
                    height: 99999px;
                    background: $grey--table;
                }

                .sync-scroll-y {
                    width: 8px;
                    height: 100%;
                    overflow-y: scroll;
                    margin-left: 4px;
                    div { width: 1px; }
                }
            }

            .table-wrapper {
                overflow-y: scroll;
                overflow-x: scroll;
                max-height: 75vh;
                max-width: fit-content;
                grid-column: 1;
                grid-row: 2;
                
                // hide main scrollbar, as its replaced with external bars
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    height: 0px;
                    width: 0px;
                }
                &.unset-scroll {
                    scroll-behavior: unset;
                }
                &.overflows {
                    &::before {
                        top: 68px;
                    }
                }

                table {
                    border-spacing: 0px;
                    border-collapse: collapse;
    
                    .sticky-header {
                        position: sticky;
                        top: 0;
                        right: 0;
                        left: 0;
                        z-index: 5;
                        background: $grey--table;
        
                        th {
                            text-align: left;
                            background: $white;
                            position: relative;
                            box-sizing: border-box;
                            padding: 0;
    
                            &.has-min-width {
                                min-width: 200px;
                            }
    
                            &:not(:empty) {
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                            
                            &.is-dragging {
                                padding-right: 10px;
                            
                                .cdk-drop-container {
                                    padding-right: 25px;
                            
                                    &::after {
                                        opacity: 0;
                                    }
                                }
                            
                                .column-search {
                                    display: none;
                                }
                            }
                        }
                    }
    
                    .no-data {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
    
                    .loading-curtain {
                        position: absolute;
                        top: 68px;
                        left: -15px;
                        width: calc(100% + 30px);
                        height: calc(100% - 55px);
                        background-color: $white;
                        z-index: 80;
    
                        app-preloader {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100px;
                        }
                    }
        
                    tr {
                        height: 50px;
                        
                        &.table-head {
                            &::after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                z-index: 4;
                                background: $grey--table;
                            }
                        }
                        &:not(.table-head) {
                            border-top: 1px solid $grey--table;   
                        }
                        &.highlight {
                            td, input[type=checkbox] {
                                background: $primary--super-light;
                            }
    
                        }
        
                        td {
                            padding: 1rem;
                            background-color: $white;
                            transition: background-color $animation-duration ease;
                        }
        
                        td, th {
                            &.leading,
                            &.trailing {
                                position: sticky;
                                left: 0px;
                                right: 0px;
                                z-index: 1;
                                width: 32px;
                                min-width: 32px;
                                padding: 0;
                                z-index: 4;

                                input[type=checkbox] {
                                    position: relative;
                                    border-color: $grey;
    
                                    &:checked {
                                        border-color: $primary;
                                    }
    
                                    &::after {
                                        content:'';
                                        position: absolute;
                                        top:-15px;
                                        bottom:-15px;
                                        left:-10px;
                                        right:-10px;
                                    }
                                }

                                button {
                                    border: none;
                                    background: none;
                                    position: relative;
                                    height: 32px;
                                    width: 32px;
                                    padding: 0;
                                    cursor: pointer;
                                    transition: opacity ($animation-duration * .7) ease;
        
                                    &::after {
                                        content: "expand_less";
                                        display: block;
                                        font-family: 'Material Icons';
                                        font-size: 32px;
                                        line-height: 1;
                                        color: $grey--strong;
                                        rotate: 180deg;
                                        transition: all $animation-duration ease-in-out;
                                    }
                                }
                            }

                            &.leading {
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 1px;
                                    height: 100%;
                                    z-index: 4;
                                    background: $grey--table;
                                }
                            }
        
                            &.trailing {
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 1px;
                                    height: 100%;
                                    z-index: 4;
                                    background: $grey--table;
                                }
                            }
                        }
                    }
        
                    tbody {
                        tr {
                            opacity: 1;
                            transition: opacity $animation-duration ease-in-out;
                        }
        
                        &.is-dragging {
                            tr {
                                opacity: .4;
                            }
                        }
                        // when connector data is shown
                        &[data-condensed="false"] {
                            tr {
                                &:first-of-type {
                                    border-top: 1px solid $primary;
                                    font-weight: 600;
                                }
                                &:last-of-type {
                                    border-bottom: 1px solid $primary;
                                }
                                td, input[type=checkbox] {
                                    background-color: $primary--super-light;
                                    &.trailing {
                                        button {
                                            &::after {
                                                color: $text;
                                                transform: rotate(180deg);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.chevron-overview-button {
    display: block;
    height: 45px;
    width: 45px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &::after {
        content: "expand_more";
        font-family: Material Icons;
        font-size: 32px;
        color: $text;
    }

    &.chevron-left {
        margin-right: 20px;
        rotate: 90deg;
    }

    &.chevron-right {
        margin-left: 20px;
        rotate: -90deg;
    }

    &.disabled {
        &::after {
            color: #C4C4C4;
        }
        cursor: default;
    }
}
